import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";

import Toast, { TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";

const app = createApp(App);

app.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 1,
  position: "top-center",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  toastClassName: "passwordlyToast",
  bodyClassName: ["text-sm", "font-medium", "p-2"],
  icon: true,
  rtl: false,
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.ERROR]: {
      closeButtonClassName: "errorButton",
    },
    [TYPE.SUCCESS]: {
      closeButtonClassName: "successButton",
    },
    [TYPE.WARNING]: {
      closeButtonClassName: "warningButton",
    },
  },
});

app.provide("$store", store);
app.provide("$router", router);
app.use(store).use(router).mount("#app");
