
export default {
  name: "PageNotFoundComp",
  components: {},

  methods: {
    async goBack() {
      this.$router.push({ name: "dashboardPage" });
    },
  },
};
